<template>
  <div>
    <el-dialog
      :title="type === 'edit' ? '编辑' : '新增'"
      :visible.sync="dialogVisible"
    >
      <el-form v-if="type === 'addIssues' || type === 'changeIssues'" ref="form" :model="form" label-width="80px">
        <el-form-item label="logo">
          <Upload v-model="form.logo" />
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="简介">
          <el-input v-model="form.desc" type="textarea" />
        </el-form-item>
        <el-form-item label="链接">
          <el-input v-model="form.url" />
        </el-form-item>
      </el-form>

      <template v-else-if="type === 'addLabels' || type === 'changeLabels'">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="名称">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="图标">
            <el-input v-model="form.icon" />
          </el-form-item>
          <el-form-item label="背景">
            <el-color-picker v-model="form.color" />
          </el-form-item>
        </el-form>
      </template>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Form, FormItem, Button, Input, ColorPicker } from 'element-ui'
import Upload from './upload.vue'
export default {
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Input.name]: Input,
    [ColorPicker.name]: ColorPicker,
    Upload
  },

  data () {
    return {
      dialogVisible: false,
      type: '', // addIssues
      form: {},
      parent: undefined
    }
  },

  methods: {
    open (type, form, parent) {
      this.type = type
      this.form = { ...form }
      this.parent = parent
      this.dialogVisible = true
    },

    close () {
      this.dialogVisible = false
    },

    submit () {
      this.$emit('submit', this.form, this.type, this.parent)
    }
  }
}
</script>

<style lang="less" scoped>

</style>

<template>
  <div>
    <el-tree
      :data="category"
      node-key="id"
      :default-expand-all="false"
      draggable
      :allow-drop="allowDrop"
      :allow-drag="allowDrag"
      :props="{ label: 'name'}"
      :expand-on-click-node="false"
      :default-expanded-keys="defaultExpandedKeys"
      @node-drop="handleDrop"
      @node-drag-start="handleDragStart"
      @node-drag-enter="handleDragEnter"
      @node-drag-leave="handleDragLeave"
      @node-drag-over="handleDragOver"
      @node-drag-end="handleDragEnd"
      @node-expand="nodeExpand"
      @node-collapse="nodeCollapse"
    >
      <template #default="{ node, data }">
        <CustomTreeNode :data="data" :node="node" @change="changeButton" @remove="removeHandler" />
      </template>
    </el-tree>

    <el-button @click="login">登录</el-button>
    <el-button @click="addButtun('addIssues')">新增Issues</el-button>
    <el-button @click="addButtun('addLabels')">新增Labels</el-button>

    <DialogForm ref="dialog" @submit="submitHandler" />
  </div>
</template>

<script>
import { Tree, Button, MessageBox, Loading } from 'element-ui'
import CustomTreeNode from './components/custom-tree-node'
import DialogForm from './components/dialog-form.vue'

import octokit from '@/api/octokit.js'
import { labelsToTree, issuesParse } from '@/utils'
import oauthProvider from '@/utils/config'
import fractionalIndex from 'fractional-index'

import { getLabels, getIssues } from '@/api'

export default {
  components: {
    CustomTreeNode,
    DialogForm,
    [Tree.name]: Tree,
    [Button.name]: Button
  },
  data () {
    return {
      labels: [],
      issues: [],
      category: [],
      map: {},

      defaultExpandedKeys: [],

      loadingInstance: undefined
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init (labels, issues) {
      this.loadingInstance = Loading.service({ lock: true, spinner: 'el-icon-loading' })
      try {
        labels = labels || await getLabels()
      } catch (error) {
        console.log(error.message)
        if (error.message === 'Bad credentials') {
          return MessageBox.alert('登录过期', '', {
            confirmButtonText: '重新登录',
            callback: action => {
              this.login()
            }
          })
        }
      }

      issues = issues || await getIssues()
      this.labels = labels
      this.issues = issues

      const { tree: labelsTree, map } = labelsToTree(JSON.parse(JSON.stringify(labels)))
      issues = issuesParse(JSON.parse(JSON.stringify(issues)))

      issues.forEach(item => {
        const id = item.categoryId
        if (map[id]) {
          if (!map[id].children) {
            this.$set(map[id], 'children', [item])
          } else {
            map[id].children.push(item)
          }
        } else {
          labelsTree.push(item)
        }
      })

      this.category = labelsTree
      this.map = map

      this.loadingInstance && this.loadingInstance.close()
    },

    // async getLabels () {
    //   try {
    //     const res = await octokit.request('GET /repos/{owner}/{repo}/labels', {
    //       owner: 'wqdygkd',
    //       repo: 'webstack-vue'
    //     })
    //     return res.data || []
    //   } catch (error) {
    //     console.log(error.message)
    //     if (error.message === 'Bad credentials') {
    //       this.$alert('登录过期', '', {
    //         confirmButtonText: '重新登录',
    //         callback: action => {
    //           this.login()
    //         }
    //       })
    //     }
    //   }
    // },

    addButtun (type, parent) {
      console.log('addButtun', type, parent)
      this.$refs.dialog.open(type, {}, parent)
    },

    changeButton (node) {
      console.log('changeButton', node)
      const { data, parent } = node
      const type = data.type === 'category' ? 'changeLabels' : 'changeIssues'

      const form = {
        originData: data,
        ...data
      }
      this.$refs.dialog.open(type, form, parent.data)
    },

    async submitHandler (form, type, parent) {
      // 补全 parent
      if (Array.isArray(parent) || !parent) {
        parent = { children: this.category }
      }
      parent.children = parent.children || []

      this.loadingInstance = Loading.service({
        lock: true,
        spinner: 'el-icon-loading'
      })
      try {
        if (type === 'addIssues') {
          await this.addIssuesConfirm(form, parent)

          this.init(this.labels)
        } else if (type === 'changeIssues') {
          await this.changeIssuesConfirm(form, parent)

          this.init(this.labels)
        } else if (type === 'changeLabels') {
          await this.changeLabelsConfirm(form, parent)
          this.init(null, this.issues)
        } else if (type === 'addLabels') {
          await this.addLabelsConfirm(form, parent)
          this.init(null, this.issues)
        }
      } catch (error) {
        console.log(error)
      }

      this.loadingInstance && this.loadingInstance.close()
    },

    async addIssuesConfirm (form, parent) {
      const { name, url, logo, desc } = form
      const children = parent.children
      const first = children.filter(item => item.type !== 'category')[0] || {}

      const A = null
      const B = first.index || 'Q'
      const index = fractionalIndex(A, B)

      const label = `${parent.parentName ? parent.parentName + ' ' : ''}${parent.name || ''}`
      const body = `链接: ${url}\r\n图标: ${logo}\r\n说明: ${desc || ''}\r\n排序: ${index}`

      await octokit.request('POST /repos/{owner}/{repo}/issues', {
        owner: 'wqdygkd',
        repo: 'webstack-vue',
        title: name,
        body,
        labels: label ? [label] : undefined
      })

      this.$refs.dialog.close()
    },

    async changeIssuesConfirm (form, parent) {
      const { name, url, logo, desc, index, number } = form
      const body = `链接: ${url}\r\n图标: ${logo}\r\n说明: ${desc || ''}\r\n排序: ${index}`
      const label = `${parent.parentName ? parent.parentName + ' ' : ''}${parent.name || ''}`

      await octokit.request('PATCH /repos/{owner}/{repo}/issues/{issue_number}', {
        owner: 'wqdygkd',
        repo: 'webstack-vue',
        issue_number: number,
        title: name,
        body,
        labels: label ? [label] : undefined
      })

      this.$refs.dialog.close()
    },

    async removeHandler (row) {
      MessageBox.confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        console.log(row)
        if (row.type === 'category') {
          const { parentName, name } = row
          await octokit.request('DELETE /repos/{owner}/{repo}/labels/{name}', {
            owner: 'wqdygkd',
            repo: 'webstack-vue',
            name: parentName ? `${parentName} ${name}` : name
          })

          this.init(null, this.issues)
        } else {
          await octokit.request('PATCH /repos/{owner}/{repo}/issues/{issue_number}', {
            owner: 'wqdygkd',
            repo: 'webstack-vue',
            issue_number: row.number,
            state: 'closed'
          })

          this.init(this.labels)
        }
      }).catch(() => {})
    },

    async addLabelsConfirm (form, parent) {
      const { name, icon = '', color = '' } = form
      const children = parent.children
      const first = children.filter(item => item.type === 'category')[0] || {}

      const A = null
      const B = first.index || 'Q'
      const index = fractionalIndex(A, B)

      await octokit.request('POST /repos/{owner}/{repo}/labels', {
        owner: 'wqdygkd',
        repo: 'webstack-vue',
        name,
        description: `${icon}|${index}`,
        color: color.slice(1) || undefined
      })

      this.$refs.dialog.close()
    },

    async changeLabelsConfirm (form, parent) {
      const { originData = {} } = form

      const name = originData.parentName ? `${originData.parentName} ${originData.name}` : originData.name
      const newName = parent.name ? `${parent.name} ${form.name}` : form.name
      const newColor = parent.color ? parent.color : form.color || ''
      const newDescription = `${form.icon || ''}|${form.index || ''}`

      if (name === newName && originData.description === newDescription && originData.color === newColor) {
        console.log('无变化')
      } else {
        await octokit.request('PATCH /repos/{owner}/{repo}/labels/{name}', {
          owner: 'wqdygkd',
          repo: 'webstack-vue',
          name: name,
          new_name: newName,
          description: newDescription,
          color: newColor.slice(1) || undefined
        })
      }

      if (name !== newName || originData.color !== newColor) {
        if (form.children) {
          await form.children.filter(item => item.type === 'category').reduce(async (previousValue, currentValue) => {
            await previousValue
            await this.changeLabelsConfirm({
              ...currentValue,
              originData: currentValue
            }, { ...form, name: newName, color: newColor, description: newDescription })
          }, [])
        }
      }
      this.$refs.dialog.close()
    },

    login () {
      localStorage.setItem('redirect', '/admin')
      const { oauth_uri: oauthUri, client_id: clientId, redirect_uri: redirectUri } = oauthProvider.github
      location.href = `${oauthUri}?client_id=${clientId}&redirect_uri=${redirectUri}`
    },

    handleDragStart (node, ev) {
      console.log('drag start', node)
    },
    handleDragEnter (draggingNode, dropNode, ev) {
      console.log('tree drag enter: ', dropNode.data.name)
    },
    handleDragLeave (draggingNode, dropNode, ev) {
      console.log('tree drag leave: ', dropNode.data.name)
    },
    handleDragOver (draggingNode, dropNode, ev) {
      console.log('tree drag over: ', dropNode.data.name)
    },
    handleDragEnd (draggingNode, dropNode, dropType, ev) {
      console.log('tree drag end: ', dropNode && dropNode.data.name, dropType)
    },

    handleDrop (draggingNode, dropNode, dropType, ev) {
      console.log('tree drop: ', draggingNode, dropNode, dropType, ev)
      let parent = dropNode.parent && dropNode.parent.data
      if (Array.isArray(parent)) {
        parent = { children: this.category }
      }
      parent.children = parent.children || []

      const drop = dropNode.data
      const dragging = draggingNode.data

      // let children = parent.children.filter(item => item.type === dragging.type)

      const dropPosition = parent.children.findIndex(item => item.id === drop.id)
      console.log(dropPosition)
      let A
      let B
      if (dropType === 'before') {
        if (dropPosition <= 1) {
          A = null
          B = drop.index || 'Q'
        } else {
          A = parent.children[dropPosition - 2].index || null
          B = drop.index || 'Q'
        }
      } else if (dropType === 'after') {
        if (dropPosition === parent.children.length - 2) {
          A = drop.index || 'Q'
          B = null
        } else {
          A = drop.index || 'Q'
          B = parent.children[dropPosition + 2].index || null
        }
      } else if (dropType === 'inner') {
        parent = drop
        const leng = drop.children.length
        A = leng - 2 === -1 ? 'Q' : drop.children[leng - 2].index
        B = null
      }

      console.log(A, B)
      const index = fractionalIndex(A, B)

      if (dragging.type === 'category') {
        const name = dragging.name
        const icon = dragging.icon || ''

        const newName = parent.name ? parent.name + ' ' + dragging.name : dragging.name
        const description = `${icon}|${index}`

        console.log(name, newName, description)

        this.submitHandler({
          originData: dragging,
          ...dragging,
          // name: newName,
          index
        }, 'changeLabels', parent)
      } else {
        this.submitHandler({
          originData: dragging,
          ...dragging,
          index
        }, 'changeIssues', parent)
      }
    },

    allowDrop (draggingNode, dropNode, type) {
      console.log('allowDrop', draggingNode, dropNode, type)
      const draggingData = draggingNode.data
      const dropData = dropNode.data

      if (draggingData.type === 'category') {
        if (dropData.type !== 'category') return false

        // 拖拽含有子分类
        if ((draggingData.children || []).some(item => item.type === 'category')) {
          if (type === 'inner' || dropNode.level === 2) return false
        } else {
          if ((dropNode.level === 2 && type === 'inner')) return false
        }
      } else {
        if (dropData.type !== 'category' && type === 'inner') return false
        if ((dropData.children || []).some(item => item.type === 'category')) {
          if (type === 'inner') return false
        }
        if (dropData.type === 'category' && (type !== 'inner')) return false
      }
      return true
    },

    allowDrag (draggingNode) {
      return true
    },

    nodeExpand (data, node, self) {
      console.log(data, node, self)
      const item = this.defaultExpandedKeys.find(item => item === data.id)
      if (item) return
      this.defaultExpandedKeys.push(data.id)
    },
    nodeCollapse (data, node, self) {
      console.log(data, node, self)
      // if (node.expanded) return false
      // if (!node.expanded) {
      this.defaultExpandedKeys = this.defaultExpandedKeys.filter(item => item !== data.id)
      // }
      // const index = this.defaultExpandedKeys.findIndex(item => item === data.id)
      // this.defaultExpandedKeys.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped>
.logo {
  width: 40px;
  height: 40px;
}
:deep(.el-tree-node__content) {
  height: 30px;
}
</style>

<template>
  <span class="custom-tree-node">
    <template v-if="data.type === 'category'">
      <span class="left" :style="{'width' : leftWidth}">
        <i class="iconfont" :class="`iconfont-${data.icon}`" />
        <span class="category-name" :style="{'background': data.color}">{{ data.name }}</span>
      </span>
      <div class="right">
        <div class="index">{{ data.index }}</div>
        <span>
          <el-button type="text" size="mini" @click="() => change()">
            修改
          </el-button>
          <el-button
            :disabled="data.children && data.children.length > 0"
            type="text"
            size="mini"
            @click="() => remove(node, data)"
          >
            删除
          </el-button>
        </span>
      </div>
    </template>
    <template v-else>
      <div class="issues">
        <div class="left" :style="{'width' : leftWidth}">
          <el-image class="logo" :src="data.logo" />
          <a :href="data.url">{{ data.name }}</a>
        </div>
        <div class="right">
          <div class="index">{{ data.index }}</div>
          <div class="desc overflowClip_1">{{ data.desc }}</div>
          <div class="time">{{ data.time }}</div>
        </div>

        <span>
          <el-button type="text" size="mini" @click="change">
            修改
          </el-button>
          <el-button type="text" size="mini" @click="() => remove(node, data)">
            删除
          </el-button>
        </span>
      </div>
    </template>
  </span>
</template>

<script>
import { Button, Image } from 'element-ui'
export default {
  components: {
    [Button.name]: Button,
    [Image.name]: Image
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    node: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    leftWidth () {
      return `${300 - (this.node.level - 1) * 18}px`
    }
  },

  methods: {
    remove () {
      this.$emit('remove', this.data)
    },

    change () {
      this.$emit('change', this.node)
    },

    add () {
      this.$emit('add', 'addIssues', this.node)
    }
  }
}
</script>

<style lang="less" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .left {
    display: flex;
    align-items: center;
    .category-name {
      padding: 0 10px;
      border-radius: 10px;
      color: #fff;
    }
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: space-between;
    .index {
      width: 20px;
    }
    .desc {
      flex: 1;
    }
    .time {
      width: 160px;
    }
  }

  .issues {
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .logo {
      width: 20px;
      height: 20px;
      padding: 5px;
    }
  }
}

.overflowClip_1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
